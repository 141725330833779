<template>
    <div class="order-page">
        <div class="container-fluid">
            <h1>Zamówienie {{ orderId }}</h1>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
            <div v-else >
                {{msg}}
            </div>            
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    name: 'AdminOrder',
    data() {
        return {
            isLoading: true,
            orderId: this.$route.params.orderId,
            msg: ''
        }
    },
    computed: {
    },
    mounted() {
        if(confirm("Na pewno?")) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.post('/admin/orderdelete', {id: this.orderId} ).then( (responce) => {
                this.isLoading =  false;
                this.msg = responce.data.message;
            }).catch( () => {
                this.isLoading = false;
                this.$bvToast.toast(`Błąd pobierania danych`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            })
        } else {
                this.isLoading =  false;
            this.msg = "Uff ok :)";
        }
    },
    methods: {
    },
}
</script>
